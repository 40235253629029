import React from "react";
import styled from "styled-components";

function PracticeAreas() {
  return (
    <ContainerDiv>
      <PracticesDiv>
        <h1>Practice Areas</h1>
        <p>
          Strategic Education Services (SES) is recognized as one of the most
          influential K-14 education lobbying firms in California. Our team of
          legislative analysts and advocates provide services ranging from
          complex fiscal analysis of the annual budget to legislative advocacy
          and strategic planning. Together, our diverse staff brings over 70
          years of cumulative experience working on education issues in state
          and local government. SES has worked with both Republicans and
          Democrats, and maintains strong relationships with the Asian Pacific
          American Legislative Caucus, the Latino Legislative Caucus, the
          Legislative Women’s Caucus, the Department of Education, the State
          Board of Education, the Department of Finance, and the Governor’s
          Office.
        </p>
        <p>
          As a firm, we are set apart by our focus on urban school districts and
          by our dedication to provide superior insight into legislative policy
          through comprehensive data analysis. Our emphasis on data analyses
          allows us the precision and insight needed to deliver the best
          possible results to our clients and offers them the ability to make
          informed decisions. Our current client list, which includes Los
          Angeles Community College District, Santa Ana Unified School District,
          and San Bernardino City Unified School District reflects our
          commitment to serve districts with a high concentration of
          disadvantaged students. The scope of SES’ qualifications includes the
          following:
        </p>
        <ul>
          <li>
            An in-depth understanding of the legislative process, rules
            governing the passage of legislation, and the implementation of new
            statutes.
          </li>
          <li>Ability to analyze legislation and the state budget.</li>
          <li>
            Skilled in preparation and delivery of testimony before the
            Legislature and state policy boards.
          </li>
          <li>
            Extensive knowledge of how the Legislature interacts with state
            agencies with regard to policy formulation, development and
            enactment of legislation, and program implementation.
          </li>
          <li>
            Solid professional relationships with an ever changing Legislature
            and the Administration.
          </li>
          <li>
            Excellent relationships with professional, statewide and local
            associations.
          </li>
          <li>
            Ability to perform fiscal analysis of new and existing programs
            including funding formulas, funding allocations, funding sources and
            costs.
          </li>
          <li>
            Expertise in local school and community college district budgets and
            the use of categorical resources.
          </li>
          <li>
            Institutional and historical knowledge of state government activity
            as it relates to education and children’s issues.
          </li>
          <li>
            A working understanding of electoral campaign techniques, campaign
            finance and the state Fair Political Practices Act.
          </li>
          <li>
            Experience in working with individuals and organizations of diverse
            backgrounds.
          </li>
          <li>
            A thorough understanding of Proposition 98, along with other state
            initiatives, and its interaction with the state budget.
          </li>
          <li>Ability to analyze the effects of state ballot propositions.</li>
        </ul>

        <p>Our services include:</p>
        <ul>
          <li>Legislative Advocacy</li>
          <li>Policy Updates and Reports</li>
          <li>Data and Statistical Analysis</li>
          <li>Consulting</li>
          <li>Coalition Representation</li>
        </ul>

        <h1>Legislative Advocacy</h1>
        <p>
          SES staff’s experience in advocating on issues of importance to our
          clients has a number of dimensions. SES currently lobbies the
          following agencies related to our clients’ areas of interest:
        </p>
        <ul>
          <li>The policy and fiscal committees of the Legislature</li>
          <li>The Office of the Legislative Analyst</li>
          <li>The Department of Finance</li>
          <li>The California Department of Education</li>
          <li>The Governor’s Office</li>
          <li>The State Allocation Board</li>
          <li>The State Board of Education</li>
          <li>The Instructional Quality Commission</li>
          <li>The Advisory Commission on Charter Schools</li>
          <li>The Advisory Commission on Special Education</li>
          <li>The State Chancellor’s Office of the CA Community Colleges</li>
          <li>The Curriculum Commission</li>
          <li>The Air Resources Board</li>
          <li>The California Energy Commission</li>
          <li>The Department of Health Care Services</li>
          <li>The Little Hoover Commission</li>
          <li>The Franchise Tax Board</li>
        </ul>

        <h1>Policy Updates and Reports</h1>
        <p>
          SES provides the following deliverables to our clients under our
          standard contract:
        </p>
        <ul>
          <li>
            Written monthly reports on recent developments in K-12 education
          </li>
          <li>
            A series of memos updating the client on the budget. These memos
            will include:
            <ul>
              <li>A memo on the Governor’s Budget as introduced, in January</li>
              <li>A memo on the Governor’s May Revision to the budget</li>
              <li>
                A memo on the legislative action on the budget and where
                appropriate a memo detailing the Governor’s line item vetoes to
                the budget. The memo also outlines the trailer bills to the
                budget.
              </li>
              <li>
                A memo on the Legislative Analyst’s November Report, which is
                issued each year providing a revenue and expenditure projection
                on what the Governor’s Budget will look like in the following
                January.
              </li>
            </ul>
          </li>
          <li>
            Where appropriate, draft language for legislation or the budget on
            issues requested by the client.
          </li>
          <li>
            Participate in meetings with key policy makers and stakeholders on
            issues of concern to the client.
          </li>
          <li>
            Participate in both administrative and legislative hearings offering
            testimony on issues of concern to the client.
          </li>
          <li>
            Prepare briefing materials, walk pieces, and handouts where
            appropriate on behalf of the client.
          </li>
        </ul>

        <h3>Sample Memos and Legislative Handouts:</h3>
        <h3>
          <a
            href="http://www.strategicadvocates.com/wp-content/uploads/2020/12/June-2020-Monthly.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Monthly Report June 2020
          </a>
        </h3>
        <h3>
          <a
            href="http://www.strategicadvocates.com/wp-content/uploads/2020/12/June-Budget-Memo-2020-21-K-12-.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Memo on the Budget June 2020
          </a>
        </h3>

        <h1>Data and Statistical Analysis</h1>
        <p>
          SES is unique from other lobbying firms in that we believe data and
          analysis is a key tool in successfully delivering the outcomes
          requested by our clients. Many of our analyses can provide data sorted
          by legislative district. Recent examples of our work include using
          data and analysis include:
        </p>
        <ul>
          <li>
            Data analysis of Governor Brown’s Local Control Funding Formula
            [LCFF]. SES has initiated a number of data analysis projects to
            provide greater insight into the Governor’s LCFF proposal for
            funding K-12 school districts. SES has organized the information
            into legislative districts for the LAUSD government relations staff
            and separated the charter school information from the district data
            to allow for more accurate comparisons among educational entities.
          </li>
          <li>
            Data analysis of Governor Brown’s Student Centered Funding Formula
            [SCFF]. SES has provided in-depth data analysis on the methodology
            and calculations of the Governor’s SCFF proposal for funding the
            California Community Colleges.
          </li>
          <li>
            Compilation of QEIA information by legislative district to defeat a
            recommendation by the Legislative Analyst to eliminate the QEIA
            program.
          </li>
          <li>
            Calculation of potential gains to school districts if Governor
            Brown’s proposal in the 2011-12 budget to eliminate redevelopment
            agencies were adopted by the legislature.
          </li>
          <li>
            Showing the impact of funding changes on school districts if AB 18
            [Brownley] were to be enacted into law.
          </li>
        </ul>
        <h3>Sample Data Analyses:</h3>
        <h3>
          <a
            href="http://www.migration.strategicadvocates.com/wp-content/uploads/2013/03/LAUSD-QEIA-Policy-Proposal-2010-Version.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            LAUSD QEIA Policy Proposal 2010
          </a>
        </h3>

        <h1>Coalition Representation</h1>
        <p>
          In addition to individual clients, SES also represents a number of
          coalitions and membership associations including:
        </p>
        <ul className="last-item">
          <li>School Transportation Coalition</li>
          <li>High School District Association</li>
          <li>Association of California Urban School Districts</li>
          <li>Education Coalition</li>
        </ul>
      </PracticesDiv>
    </ContainerDiv>
  );
}
const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    h2 {
      margin-right: 0.5rem;
    }
  }
`;
const PracticesDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  .buttons {
    @media (max-width: 768px) {
      display: flex;
    }
    margin-bottom: 1rem;
    .second-button {
      margin-left: 2rem;
    }

    button {
      font-size: 1.5rem;
      height: 7.5rem;
      width: 15rem;
      background-color: #78b7fb;
      color: #000000;
      border-radius: 0.3rem;
      border: 1px solid black;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;

      &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
      }
    }

    @media (max-width: 768px) {
      .buttons {
        display: flex;
      }
      button {
        height: 4rem;
        width: 8rem;
        font-size: 1rem;
      }

      .second-button {
        margin-left: 1rem;
      }
    }
  }

  .last-item {
    margin-bottom: 5rem;
  }
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    button {
      width: 7rem;
    }
  }
  h3 {
    font-size: 1rem;
  }
`;

export default PracticeAreas;
