import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Grouping image imports
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
import img7 from "./img7.jpg";
import img8 from "./img8.jpg";
const images = [img1, img2, img3, img4, img5, img6, img7, img8];

function ImageCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "linear",
  };

  return (
    <StyledDiv>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  div {
    height: 25rem;
    width: 40rem;
  }
  img {
    height: 25rem;
    width: 40rem;
    object-fit: cover;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    div {
      height: 15rem;
      width: 15rem;
    }

    img {
      height: 15rem;
      width: 15rem;
      object-fit: cover;
    }
  }
`;

export default ImageCarousel;
