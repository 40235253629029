import React from "react";
import styled from "styled-components";

function Clients() {
  return (
    <ContainerDiv>
      <ClientDiv>
        <div style={{ display: "flex" }}>
          <div>
            <h1>Clients</h1>
            <h3>
              <a
                href="https://publishers.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Association of American Publishers Higher Education
              </a>
            </h3>
            <h3>
              <a
                href="https://castoways.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Association of Transportation Officials
              </a>
            </h3>
            <h3>
              <a
                href="https://www.cerritos.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cerritos College
              </a>
            </h3>
            <h3>
              <a
                href="https://www.latinas.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hispanas Organized for Political Equality
              </a>
            </h3>
            <h3>
              <a
                href="https://pasadena.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pasadena City College
              </a>
            </h3>
            <h3>
              <a
                href="https://www.laccd.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Los Angeles Community College District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.lbcc.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Long Beach Community College
              </a>
            </h3>
            <h3>
              <a
                href="https://www.smc.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Santa Monica College
              </a>
            </h3>
            <h3>School Transportation</h3>

            <h1>Consulting</h1>
            <h3>
              <a
                href="https://www.gatesfoundation.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bill Melinda Gates Foundation
              </a>
            </h3>
            <h3>
              <a
                href="https://www.cta.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Teachers Association
              </a>
            </h3>
            <h3>
              <a
                href="https://capta.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Education Coalition
              </a>
            </h3>
            <h3>
              <a
                href="https://learningpolicyinstitute.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learning Policy Institute
              </a>
            </h3>
            <h3>
              <a
                href="https://stuartfoundation.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stuart Foundation
              </a>
            </h3>
          </div>
          <div>
            <h1>Past Clients</h1>
            <h3>
              <a
                href="https://www.advancingjustice.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                *Advancing Justice
              </a>
            </h3>
            <h3>
              <a
                href="https://www.avid.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Advancement via Individual Determination (AVID)
              </a>
            </h3>
            <h3>Association of California Urban School Districts</h3>
            <h3>
              <a
                href="https://castoways.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Association of School Transportation Officials
              </a>
            </h3>
            <h3>
              <a
                href="https://cacatholic.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                California Catholic Conference
              </a>
            </h3>
            <h3>
              <a
                href="http://members.csea.com/memberhome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                *California School Employees Association
              </a>
            </h3>
            <h3>California Science Center Foundation</h3>
            <h3>
              <a
                href="https://collegecampaign.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Campaign for College Opportunity
              </a>
            </h3>
            <h3>
              <a
                href="https://caasf.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chinese for Affirmative Action
              </a>
            </h3>
            <h3>
              <a
                href="https://www.ccsf.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                City College of San Francisco
              </a>
            </h3>
            <h3>
              <a
                href="https://www.collegeboard.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The College Board
              </a>
            </h3>
            <h3>
              <a
                href="https://www1.marin.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                College of Marin
              </a>
            </h3>
            <h3>
              <a
                href="https://www.connectionsacademy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Connections Academy
              </a>
            </h3>
            <h3>
              <a
                href="https://www.dinuba.k12.ca.us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dinuba Unified School District
              </a>
            </h3>
            <h3>High School Districts Association</h3>
            <h3>
              <a
                href="https://learnbehavioral.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn It Systems
              </a>
            </h3>
            <h3>
              <a
                href="https://losrios.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Los Rios Community College District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.lacoe.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Los Angeles County Office of Education
              </a>
            </h3>
            <h3>
              <a
                href="https://www.lausd.org/domain/4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Los Angeles Unified School District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.montebello.k12.ca.us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Montebello Unified School District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.peacebuilders.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PeaceBuilders
              </a>
            </h3>
            <h3>
              <a
                href="http://www.roundpeaksystems.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Roundpeak Systems
              </a>
            </h3>
            <h3>
              <a
                href="https://www.sbcusd.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                San Bernardino City Unified School District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.sausd.us/site/default.aspx?PageID=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Santa Ana Unified School District
              </a>
            </h3>
            <h3>School Transportation Coalition</h3>
            <h3>
              <a
                href="https://www.socccd.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                South Orange County Community College District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.scusd.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sacramento City Unified School District
              </a>
            </h3>
            <h3>
              <a
                href="https://www.swccd.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Southwestern Community College
              </a>
            </h3>
            <h3>
              <a
                href="https://www.stocktonusd.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stockton Unified School District
              </a>
            </h3>
            <h3 className="last-item">
              <a
                href="https://www.ti.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                *Texas Instruments
              </a>
            </h3>
          </div>
        </div>
      </ClientDiv>
    </ContainerDiv>
  );
}

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  @media (max-width: 768px) {
    margin-left: 1rem; // You can adjust the margin as per your need
    margin-right: 1rem;
  }
  div {
    margin: 1rem;
  }
`;

const ClientDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  //more white space on the bottom
  .last-item {
    margin-bottom: 10rem;
  }

  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
  }
  h3 {
    font-size: 1rem;
  }
`;
export default Clients;
