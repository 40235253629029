import React from "react";
import styled from "styled-components";
function CALegislatureAddresses32BillionDeficit() {
  return (
    <ArticleDiv>
      <h2>
        California Legislature Addresses $32 Billion Deficit with New Budget
      </h2>
      <p>
        Sacramento lawmakers have approved a $310.8 billion budget to counter a
        $32 billion shortfall. This year, economic challenges arose from
        inflation and a turbulent stock market, which caused the state’s
        expenses to surpass revenues by over $30 billion.
      </p>
      <p>
        The recently approved budget introduces a suite of strategic financial
        measures. It incorporates significant spending cuts that total $8
        billion and employs a combination of delayed and reallocated expenses.
        Additionally, the plan involves borrowing an amount of $6.1 billion.
        Notably, this budget sets a milestone with a record reserve allocation,
        earmarking an impressive $37.8 billion
      </p>
      <p>
        Furthermore, tax incentives for the state’s film and television industry
        have been extended and enhanced, with refundable credits that could
        impact state revenues by up to $330 million annually. While some
        legislators argue this move is necessary given the industry’s
        significance and job creation potential, others question its
        justification amidst fiscal challenges.
      </p>
      <p>
        The budget, expected to be signed by Gov. Gavin Newsom, also introduces
        protections for the Joshua tree, charging developers a fee for its
        removal, aiming for preservation. Public transit agencies, hit hard
        during the pandemic, will receive a share of $5.1 billion over three
        years. Additionally, a new tax on private Medicaid partners is projected
        to garner $32 billion over four years.
      </p>
      <p className="link-spacing">
        Read more here:
        <a
          href="https://apnews.com/article/california-state-budget-2023-2db8442a1d7c0ecc5b6827207c1dad03"
          target="_blank"
          rel="noopener noreferrer"
        >
          apnews.com
        </a>
      </p>
    </ArticleDiv>
  );
}
const ArticleDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 2rem;
  a {
    color: black;
    margin-left: 0.3rem;
  }
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
export default CALegislatureAddresses32BillionDeficit;
