import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
function Internship() {
  const navigate = useNavigate();
  return (
    <ContainerDiv>
      <StyledIntern>
        <h1>Interships</h1>
        <h2>Why SES?</h2>
        <h3>Pioneers in Advocacy</h3>
        <p>
          With over 70 years of combined experience, our team excels in
          legislative advocacy, strategic planning, and comprehensive data
          analysis.
        </p>
        <h3>Diverse Portfolio</h3>
        <p>
          We have worked extensively with both political spectrums and key
          organizations like the Department of Education, State Board of
          Education, and the Governor’s Office.
        </p>

        <h2>What Will You Learn?</h2>
        <p>
          The intricacies of the legislative process and the rules governing
          legislation.
        </p>
        <p>
          Data-driven decision making with an emphasis on education-related
          issues.
        </p>
        <p>Fiscal analysis of new and existing educational programs.</p>
        <p>
          Work dynamics in a firm with solid relationships with the Legislature,
          Administration, and a variety of state agencies.
        </p>
        <p>
          Insight into electoral campaign techniques and the Fair Political
          Practices Act.
        </p>
        <h3> Unique Opportunity to Dive into Data</h3>
        <p>
          SES stands apart with our rigorous emphasis on data analysis. You'll
          get a chance to work on projects like LCFF and SCFF proposals, among
          others.
        </p>
        <h3>Coalition Representation</h3>
        <p>
          Work alongside teams representing associations like the School
          Transportation Coalition and the Education Coalition.
        </p>
        <h3>Location: Sacramento, California (remote)</h3>
        <h2 className="intern" onClick={() => navigate("/Internships/apply")}>
          Apply now!
        </h2>
      </StyledIntern>
    </ContainerDiv>
  );
}
const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const StyledIntern = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    .first-item {
      margin-top: 1rem;
    }
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
  h1 {
    font-size: 2.5rem;
  }
  .intern {
    margin-bottom: 5rem;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
    text-decoration: underline;
    &:hover {
      transform: scale(1.02);
    }
  }
`;
export default Internship;
