import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function ApplyUpdated() {
  const navigate = useNavigate();
  return (
    <SuccessDiv>
      <SuccessContainer>
        <div className="main-div">
          <h1>Apply</h1>
          <p>Please email your resume and cover letter to:</p>
          <p className="bold-face">info@strategicadvocates.com</p>
          <p>
            We will review your application and contact you regarding the next
            steps.
          </p>
          <p>Thank you for your interest</p>
          <button onClick={() => navigate("/")}>Home</button>
        </div>
      </SuccessContainer>
    </SuccessDiv>
  );
}

const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-left: 10rem;
  margin-right: 10rem;
  font-family: "Open Sans", sans-serif;

  button {
    width: 50%;
    height: 2rem;
    margin-top: 1rem;
    /* @media (max-width: 768px) {
      width: 75%;
    } */
    background-color: #007bff;
    color: white;
    border-radius: 0.3rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: #0056b3;
      transform: scale(1.05);
    }
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: 0px 0px 10px lightgray;
  box-shadow: 0;
  height: 25rem;
  width: 30rem;
  background-color: white;

  .bold-face {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    .main-div {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    height: 30rem;
    width: 20rem;
  }
`;

export default ApplyUpdated;
