import React from "react";
import styled from "styled-components";

function TK12() {
  return (
    <TK12Div>
      <h1>TK12</h1>
      <h2>LCFF/LCAP</h2>
      <h2>Curriculum and Frameworks</h2>
      <h2>Social Change</h2>
    </TK12Div>
  );
}

const TK12Div = styled.div`
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  margin-left: 10rem;
  margin-right: 10rem;
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    h1 {
      font-size: 1.5rem;
    }
  }
`;
export default TK12;
