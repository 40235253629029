import React, { useState } from "react";
import styled from "styled-components";

function LinksResources() {
  //const [isOverview, setIsOverview] = useState(false);
  //const [isToolkits, setIsToolkits] = useState(false);
  return (
    <Container>
      <LinksDiv>
        <h1>Links and Resources</h1>
        <h2>LCFF Resources</h2>
        <h3>Overview and Guidelines</h3>
        <ul>
          <li>
            <a
              href="http://lcff.wested.org/lcff-channel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WestEd LCFF Guidelines
            </a>
          </li>
          <li>
            <a
              href="https://www.lao.ca.gov/reports/2013/edu/lcff/lcff-072913.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              LAO LCFF Overview
            </a>
          </li>
          <li>
            <a
              href="https://www.csba.org/GovernanceAndPolicyResources/FairFunding/~/media/CSBA/Files/GovernanceResources/EducationIssues/FairFunding/LCFF/LCFF-LCAP_GovCalendar_2013-15.ashx"
              target="_blank"
              rel="noopener noreferrer"
            >
              LCFF & LCAP Governance Calendar Integration
            </a>
          </li>
        </ul>
        <h3>Toolkits</h3>
        <ul>
          <li>
            <a
              href="http://www.migration.strategicadvocates.com/wp-content/uploads/2014/04/2014-15-CCSESA-LCAP-Approval-Manual.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              California County Superintendents Educational Services Association
              LCAP Approval Manual
            </a>
          </li>
          <li>
            <a
              href="http://www.migration.strategicadvocates.com/wp-content/uploads/2014/04/Dual-Approval-Process-LCAP-Budget.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              California County Superintendents Educational Services Association
              Dual Approval Process LCAP Nexus
            </a>
          </li>
          <li>
            <a
              href="https://www.csba.org/GovernanceAndPolicyResources/FairFunding/LCFFToolkit.aspx#gsc.tab=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              CSBA LCFF ToolKit
            </a>
          </li>
          <li>
            <a
              href="http://downloads.capta.org/edu/e-school-finance/NationalStandardsAssessmentGuide-CAPTA_Assssment%20Guide.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              PTA Family-School Parent Engagement Assessment Guide
            </a>
          </li>
          <li>
            <a
              href="https://ui.benchmarkemail.com/c/i?e=468104&c=4592C&l=E156990&email=wmP4ECXwsbtfrgx2giWEKp5bEC9QDhkXcZax5XQi2o0%3D&relid=C0A80267"
              target="_blank"
              rel="noopener noreferrer"
            >
              Additional PTA Family-School Parent Engagement Resources
            </a>
          </li>
          <li>
            <a
              href="https://www.csba.org/GovernanceAndPolicyResources/FairFunding/~/media/CSBA/Files/GovernanceResources/EducationIssues/ConditionsofChildren/2009BuildingHealthyCommunitites.ashx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Building Health Communities Community Engagement Guide
            </a>
          </li>
        </ul>
        <h2>Education Links</h2>
        <h3>
          <a
            href="http://www.cde.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Department of Education
          </a>
        </h3>
        <h3>
          <a
            href="https://www.cccco.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Community Colleges Chancellor’s Office
          </a>
        </h3>
        <h3>
          <a
            href="https://ccleague.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Community College League of California
          </a>
        </h3>
        <h3>
          <a
            href="https://edsource.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            EdSource
          </a>
        </h3>

        <h2>Legislative Links</h2>
        <h3>
          <a
            href="https://www.gov.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Governor’s Home Page
          </a>
        </h3>
        <h3>
          <a
            href="https://www.senate.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Senate Home Page
          </a>
        </h3>
        <h3>
          <a
            href="https://www.assembly.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Assembly Home Page
          </a>
        </h3>
        <h3>
          <a
            href="http://www.leginfo.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Legislative Information
          </a>
        </h3>
        <h3>
          <a
            href="http://www.calchannel.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Channel
          </a>
        </h3>

        <h2>Additional Resources</h2>
        <h3>
          <a
            href="https://dof.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Department of Finance
          </a>
        </h3>
        <h3>
          <a
            href="https://www.lao.ca.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legislative Analyst’s Office
          </a>
        </h3>
        <h3>
          <a
            href="https://www.ppic.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Public Policy Institute of California
          </a>
        </h3>
      </LinksDiv>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  @media (max-width: 768px) {
    margin-left: 1rem; // You can adjust the margin as per your need
    margin-right: 1rem;
  }
`;
const LinksDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  h3 {
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
  }
  li {
    margin-bottom: 1.5rem;
  }
`;
export default LinksResources;
