import { createGlobalStyle, keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const GlobalAnimations = createGlobalStyle`
    .fade-in {
    animation: ${fadeIn} 1.5s linear;
  }
`;

export const GlobalStyles = createGlobalStyle`
body{
  background: linear-gradient(
  90deg,
  #caf2ff 0%,
  #85c0ff 100%
);
}
html, body {
  margin: 0;
  padding: 0;
}
p, span, h1, h2, h3, h4, a{
  font-family: 'Roboto', sans-serif;
  font-weight: normal;

}

`;
