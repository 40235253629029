import React from "react";
import styled from "styled-components";

function BenefitsOfAttendingACommunityCollege() {
  return (
    <ArticleDiv>
      <h2>Benefits of Attending a Community College</h2>
      <p>
        Community Colleges offer several benefits: affordability, four-year
        college transition, proximity, flexibility, and workforce readiness.
      </p>
      <p>
        Community colleges cost significantly less than four-year universities.
        They also often provide financial aid and scholarships.
      </p>
      <p>
        Many have transfer agreements with universities, allowing students to
        complete an associate’s degree and then seamlessly transfer, effectively
        reducing the cost and duration of a bachelor’s degree.
      </p>
      <p>
        Being local, they provide students the comfort of studying near home,
        making higher education more accessible to those hesitant or unable to
        relocate.
      </p>
      <p>
        With adaptable schedules, community colleges cater to diverse student
        needs, including working adults, parents, and high school students
        looking for advanced coursework.
      </p>
      <p>
        They offer practical career and technical programs, often in
        collaboration with local employers, that lead directly to job
        opportunities. Some even offer specialized baccalaureate programs
        focusing on practical skills.
      </p>
      <p>
        Read more here:
        <a
          href="https://www.usnews.com/education/community-colleges/articles/reasons-to-consider-community-college"
          target="_blank"
          rel="noopener noreferrer"
        >
          Public Policy Institute of California
        </a>
      </p>
    </ArticleDiv>
  );
}

const ArticleDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  margin-top: 2rem;
  margin-left: 10rem;
  margin-right: 10rem;
  a {
    color: black;
    margin-left: 0.3rem;
  }
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
export default BenefitsOfAttendingACommunityCollege;
