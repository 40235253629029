import React, { useState, useEffect } from "react";
import styled from "styled-components";

function News() {
  const [feed, setFeed] = useState(null);

  // New function to fetch the RSS feed from your server
  async function fetchRSSFeed() {
    try {
      const response = await fetch(
        "https://strategic-advocates-ee121a49bc57.herokuapp.com/getRssFeed"
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching RSS feed from server:", error);
      return null;
    }
  }

  useEffect(() => {
    async function loadFeed() {
      const parsedFeed = await fetchRSSFeed();
      console.log(parsedFeed);
      setFeed(parsedFeed?.rss.channel[0]?.item || []); // Update this line to match the structure of your parsed JSON
    }

    loadFeed();
  }, []);

  function cleanString(str) {
    return str.replace(/â/g, "‘").replace(/â/g, "’");
  }

  if (!feed) return <div>Loading...</div>;

  return (
    <StyledNews>
      <h1>Edsource News</h1>
      {feed.slice(0, 10).map((item, index) => (
        <div key={index}>
          <h2>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {cleanString(item.title[0])}
            </a>
          </h2>
          <p>{cleanString(item.description[0])}</p>
          <p>{item.pubDate[0]}</p>
        </div>
      ))}
    </StyledNews>
  );
}

const StyledNews = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  margin-left: 10rem;
  margin-right: 10rem;
  font-family: "Open Sans", sans-serif;

  h1 {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    h1 {
      font-size: 1.5rem;
    }
  }
`;
export default News;
