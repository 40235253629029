import React from "react";
import styled from "styled-components";
import ImageCarousel from "./Images/ImageCarousel";
import { useNavigate } from "react-router-dom";
function Home() {
  const Navigate = useNavigate();
  return (
    <StyledHome>
      <h1>SES: Empowering Education through Data Driven Insight & Advocacy</h1>
      <p>
        Strategically advocating for quality education in California, Strategic
        Education Services (SES) harnesses its expertise in legislative
        lobbying, data analysis, and comprehensive policy knowledge to empower
        urban school districts and ensure optimal educational outcomes. With
        over 70 years of collective experience and a commitment to disadvantaged
        students, our firm not only navigates the complex political landscape
        but also keeps stakeholders informed and involved.
      </p>
      <ImageCarousel />

      <h2 className="intern" onClick={() => Navigate("/Internships")}>
        Looking for ambitious interns to join our team now!
      </h2>

      <div className="body">
        <h2
          className="articles"
          onClick={() =>
            Navigate("/Articles/benefits_of_attending_a_community_college")
          }
        >
          Benefits of Attending a Community College
        </h2>
        <p>
          Community colleges are affordable, local, offer flexible schedules,
          help with transferring to 4-year colleges, and prepare students for
          immediate jobs.
        </p>
        <h3>August 9, 2023</h3>

        <h2
          className="articles"
          onClick={() =>
            Navigate("/Articles/ca_legislature_addresses_32_billion_deficit")
          }
        >
          California Legislature Addresses $32 Billion Deficit with New Budget
        </h2>
        <p>
          California has approved a $310.8 billion budget to tackle a $32
          billion deficit. This includes $8 billion in spending cuts, a record
          $37.8 billion reserve, borrowing $6.1 billion, and tax breaks for the
          film industry.
        </p>
        <h3 className="last-item">August 9, 2023</h3>
      </div>
    </StyledHome>
  );
}

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-left: 10rem;
  margin-right: 10rem;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  .intern {
    margin-top: 3rem;
    margin-bottom: 3rem;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
    background-color: white;
    &:hover {
      transform: scale(1.05);
    }

    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 5px #aaaaaa;
    }
    50% {
      box-shadow: 0 0 10px #413c3b, 0 0 15px #0a0300;
    }
    100% {
      box-shadow: 0 0 5px #000000;
    }
  }
  .header {
    margin-top: 4rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
  }

  .articles {
    text-decoration: underline;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    .first-item {
      margin-top: 1rem;
    }
    h1 {
      font-size: 1.5rem;
    }
  }

  .last-item {
    margin-bottom: 5rem;
  }
`;

export default Home;
