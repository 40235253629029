import AboutUs from "./Components/AboutUs";
import Clients from "./Components/Clients";
import HigherEducation from "./Components/HigherEducation";
import Home from "./Components/Home";
import Internship from "./Components/Internship";
import LinksResources from "./Components/LinksResources";
import Nav from "./Components/Nav";
import News from "./Components/News";
import PracticeAreas from "./Components/PracticeAreas";
import ApplyUpdated from "./Components/ApplyUpdated";
import TK12 from "./Components/TK12";
import { GlobalAnimations } from "./Components/Styles";
import { GlobalStyles } from "./Components/Styles";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BenefitsOfAttendingACommunityCollege from "./Articles/BenefitsOfAttendingACommunityCollege";
import CALegislatureAddresses32BillionDeficit from "./Articles/CALegislatureAddresses32BillionDeficit";
function App() {
  const location = useLocation();
  return (
    <div>
      <GlobalStyles />
      <Nav />
      <GlobalAnimations />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <div className="fade-in" key={location.pathname}>
              <Home />
            </div>
          }
        />
        <Route
          path="/Articles/benefits_of_attending_a_community_college"
          element={
            <div className="fade-in" key={location.pathname}>
              <BenefitsOfAttendingACommunityCollege />
            </div>
          }
        />
        <Route
          path="/About_Us"
          element={
            <div className="fade-in" key={location.pathname}>
              <AboutUs />
            </div>
          }
        />

        <Route
          path="/Articles/ca_legislature_addresses_32_billion_deficit"
          element={
            <div className="fade-in" key={location.pathname}>
              <CALegislatureAddresses32BillionDeficit />
            </div>
          }
        />
        <Route
          path="/Clients"
          element={
            <div className="fade-in" key={location.pathname}>
              <Clients />
            </div>
          }
        />
        <Route
          path="/Practices/Higher_Education"
          element={
            <div className="fade-in" key={location.pathname}>
              <HigherEducation />
            </div>
          }
        />
        <Route
          path="/Internships"
          element={
            <div className="fade-in" key={location.pathname}>
              <Internship />
            </div>
          }
        />
        <Route
          path="/Links_Resources"
          element={
            <div className="fade-in" key={location.pathname}>
              <LinksResources />
            </div>
          }
        />
        <Route
          path="/News"
          element={
            <div className="fade-in" key={location.pathname}>
              <News />
            </div>
          }
        />
        <Route
          path="/Practices"
          element={
            <div className="fade-in" key={location.pathname}>
              <PracticeAreas />
            </div>
          }
        />
        <Route
          path="/Internships/apply"
          element={
            <div className="fade-in" key={location.pathname}>
              <ApplyUpdated />
            </div>
          }
        />
        <Route
          path="/Practices/TK-12"
          element={
            <div className="fade-in" key={location.pathname}>
              <TK12 />
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
