import React from "react";
import styled from "styled-components";

function HigherEducation() {
  return (
    <HigherEdDiv>
      <h1>Higher Education</h1>
      <h2>SCFF</h2>
      <h2>Baccalaureate Degree Program</h2>
      <h2>Transfer Pathways</h2>
    </HigherEdDiv>
  );
}

const HigherEdDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  padding: 1rem;
  background-color: white;
  margin-left: 10rem;
  margin-right: 10rem;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    h1 {
      font-size: 1.5rem;
    }
  }
`;

export default HigherEducation;
