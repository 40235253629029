import React from "react";
import styled from "styled-components";
function AboutUs() {
  return (
    <Container>
      <AboutDiv>
        <h1>About us</h1>
        <h3>STRATEGIC EDUCATION SERVICES</h3>
        <h3>1610 R Street, Suite 300 </h3>
        <h3>Sacramento, CA 95811</h3>
        <h3>phone: 916-441-3909</h3>
        <h3>email:info@strategicadvocates.com</h3>
        <h1>Meet the team</h1>
        <div>
          <h2>David Neben - </h2>
          <h3 className="italic">CEO</h3>
        </div>
        <p>
          Mr. Neben is the Chief Executive Officer, lead advocate and
          legislative analyst.
        </p>
        <p>
          Mr. Neben studied for four years at the University of California at
          Davis, where he received a Bachelor of the Arts in Political Science
          and a Minor in French Language Arts. Mr. Neben studied data analytics
          and mathematics before transitioning into a political science field,
          with an emphasis on political philosophy.{" "}
        </p>
        <p>
          Originally from the east coast, David moved to California in early
          2000 before moving to the greater Sacramento area. For over 20 years,
          he has been a member of the United States Soccer Federation and the
          National Intercollegiate Soccer Officials Association as a mentor and
          coach to youth soccer referees and coaches.{" "}
        </p>
        <p>
          As an analyst, Mr. Neben synthesizes complex information from enormous
          data sets into useful information for policymakers to make critical
          policy decisions. To that end, his primary duties are to analyze data
          sets and compile the information into more easy-to-comprehend
          material, perform complex calculations, and provide mathematical
          insight for policy analysis.{" "}
        </p>
        <p>
          Mr. Neben served as the lead analyst and consultant for the School
          Medi-Cal Administrative Activities project on behalf of K-12 school
          districts statewide from 2014 to 2018. As a result of data analytics
          work, K-12 school districts secured more than $1.1 billion to
          reimburse expenditures during the Great Recession. Additionally, Mr.
          Neben provides expert guidance on annual Proposition 98 certification
          to ensure the integrity of the K-14 minimum funding guarantee.{" "}
        </p>
        <div>
          <h2>Carol Gonzalez -</h2>
          <h3 className="italic">Senior Legislative Advocate</h3>
        </div>
        <p>
          Carol serves as a Senior Legislative Advocate for SES, where she will
          help lead advocacy activities, present public testimony, share timely
          reports, bill tracking, research, and other activities as necessary.{" "}
        </p>
        <p>
          Ms. Gonzalez earned a Bachelor of Arts in Political Science and
          Communications Studies from California State University, Sacramento.
          Carol is a proud alumnus of nonpartisan organizations, including the
          New Leaders Council and the New Leaders of America.{" "}
        </p>
        <p>
          Carol served on various community-led organizations and boards,
          including a Nationwide nonprofit dedicated to mentoring high school
          Latinas, Advancing Latinas into Leadership.{" "}
        </p>
        <p>
          She previously worked for Planned Parenthood, Committee for Hispanic
          Causes [CHC] BOLD PAC in Washington D.C., local campaigns, and the
          Secretary of State’s office in the Elections Division. She is also
          fluent in Spanish.{" "}
        </p>
        <div>
          <h2>Sabrina Means -</h2>
          <h3 className="italic">Legislative Advocate</h3>
        </div>
        <p>
          Sabrina Means serves as a Legislative Advocate, where she helps with
          client outreach and bill tracking, assists in planning Lobby Days and
          other advocacy meetings, and provides support as needed.{" "}
        </p>
        <p>
          Sabrina holds a Master’s degree in International Relations from
          California State University, Sacramento, and a bachelor’s degree in
          International Affairs from the University of California, Davis.
          Sabrina previously worked as a Legislative Advocate with Shaw Yoder
          Antwih Schmelzer & Lange, focusing primarily on public transit and
          transportation issues.
        </p>
        <p>
          Sabrina has also worked at the legislative offices of the Sierra Club
          and the Office of Public Affairs with the California Public Employees’
          Retirement System (CalPERS).
        </p>
        <p>
          Shortly after joining SES, Sabrina organized the Education Coalition’s
          outreach efforts, budget letters, and advocacy meetings to protect
          Proposition 98 and funding for K-12 students.
        </p>
        <div>
          <h2>Gay Satow -</h2>
          <h3 className="italic">Business Manager</h3>
        </div>
        <p>
          Gay Satow is a bookkeeper with 19 years of experience servicing small
          businesses in the greater Sacramento area with their bookkeeping and
          financial needs.
        </p>
        <p>
          Her experience includes working with clients as an independent
          contractor and servicing small business accounts for a CPA firm. She
          has helped a wide client base in consulting, dentistry,
          restaurants/cafes, remodeling/contracting, and other small business
          types.
        </p>
        <p>
          Gay Satow serves as the Business Manager of Strategic Education
          Services and handles accounts receivable, accounts payable, and other
          financial affairs related to this project.
        </p>
        <div>
          <h2>Dale Shimasaki </h2>
          <h3 className="italic">CEO Emeritus</h3>
        </div>
      </AboutDiv>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;
  padding: 1rem;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  background-color: white;
  @media (max-width: 768px) {
    margin-left: 1rem; // You can adjust the margin as per your need
    margin-right: 1rem;
  }
`;
const AboutDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  @media (max-width: 768px) {
    font-family: "Open Sans", sans-serif;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
  }

  img {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    margin-top: 3rem;
  }
  div {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .italic {
    margin-left: 0.5rem;
    font-style: italic;
  }
`;

export default AboutUs;
