import React, { useState } from "react";
import SES_Logo from "./SES_Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

function Nav() {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const navOptions = [
    { value: "/", label: "Home" },
    { value: "/About_Us", label: "About Us" },
    {
      value: "/Practices",
      label: "Our Practices",
      children: [
        { value: "/Practices/Higher_Education", label: "Higher Education" },
        { value: "/Practices/TK-12", label: "TK-12" },
      ],
    },
    { value: "/Clients", label: "Clients" },
    { value: "/Links_Resources", label: "Links and Resources" },
    { value: "/Internships", label: "Internships" },
    { value: "/News", label: "News" },
  ];

  return (
    <StyledNav>
      <div className="home-bar">
        <Link to="/">
          <img src={SES_Logo} alt="Logo"></img>
        </Link>
        <Link to="/">
          <h1>Strategic Education Service</h1>
        </Link>
      </div>
      <div className="nav-bar">
        {navOptions.map((option) => (
          <div
            className="nav-item"
            onMouseEnter={() => setSubMenuOpen(option.value)}
            onMouseLeave={() => setSubMenuOpen(null)}
            onClick={(e) => {
              navigate(option.value);
              e.stopPropagation();
            }}
          >
            <span>{option.label}</span>
            {option.label === "Our Practices" ? (
              <span className="arrow">&#9658;</span>
            ) : (
              ""
            )}
            {subMenuOpen === option.value && option.children && (
              <div className="sub-menu">
                {option.children.map((child) => (
                  <Link
                    to={child.value}
                    onClick={(e) => {
                      console.log(child.value);
                      e.stopPropagation();
                    }}
                  >
                    {child.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </StyledNav>
  );
}
const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  background-color: #f1ffffd0;
  a {
    text-decoration: none;
    color: black;
  }
  .nav-item {
    position: relative;
    margin-right: 0.5rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: #e6f7ff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: transform 0.2s;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    @media (max-width: 768px) {
      padding: 5px 10px;
      margin-bottom: 0.5rem;
    }
  }
  .nav-item:hover {
    background-color: #f7f7f7;
    transition: background-color 0.3s ease;
    transform: translateY(-2 px);
  }

  .sub-menu {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    z-index: 10;
    width: 100%;
    border-radius: 0 0 5px 5px;

    a {
      display: block;
      padding: 8px 20px;
      color: black;
      transition: background-color 0.3s ease;

      &:hover {
        // Hover effect for the links
        background-color: #e6f7ff;
      }
    }
  }

  .nav-item:hover .sub-menu {
    display: block;
  }

  .home-bar {
    display: flex;
    align-items: flex-end;
    h1 {
      margin: 0;
      font-size: 3rem;
      margin-left: 1rem;
      font-weight: normal;
    }

    img {
      margin-top: 1rem;
      margin-left: 2rem;
      height: auto;
      width: 5rem;
    }
  }
  .nav-bar {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
    display: flex;
    h3 {
      margin-right: 1rem;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .arrow {
      margin-left: 8px;
    }
  }
  //mobile styling
  @media (max-width: 768px) {
    .home-bar {
      margin-right: 1rem;
      justify-content: center;
      text-align: center;
      align-items: center;

      h1 {
        font-size: 1.5rem;
      }
      img {
        height: 7rem;
      }
    }
    .nav-bar {
      h3 {
        margin: 0.5rem 0.5rem;
        font-size: 1rem;
      }
    }
  }
`;

export default Nav;
